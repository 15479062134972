<template>
<v-expand-transition>
  <div v-show="visible" class="shoot-day-details">
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
        <tr>
          <th>Field</th>
          <th v-for="name in users" :key="name">
            {{ name }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(field, i) in rows" :key="i">
          <td>{{ field | title }}</td>
          <th v-for="entry in entries" :key="entry.id">
            <template v-if="field === 'lunch_hours'">
              {{ entry[field] }}
            </template>
            <template v-else>
              {{ entry[field] | extractTime }}
            </template>
          </th>
        </tr>
        <tr>
          <td></td>
          <td v-for="e in entries" :key="`blank-${e.id}`"></td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</v-expand-transition>
</template>

<script>
import * as api from '../../api'
import { entryExtraTimeFields } from '../../data'

export default {
  name: 'ShootDayDetails',
  props: ['jobId', 'date'],
  data() {
    return {
      visible: false,
      entries: []
    }
  },
  computed: {
    users() {
      return this.entries.map(e => e.name)
    },
    rows() {
      const fields = [...entryExtraTimeFields.shoot]
      const tail = fields.splice(fields.indexOf('lunch') + 1)
      return fields.concat(['lunch_hours'], tail)
    }
  },
  methods: {
    async show(on = true) {
      if (on) {
        const { jobId, date } = this
        this.entries = await api.get('/shoot/day/details', { job_id: jobId, date })
      }
      this.visible = on
    }
  }
}
</script>

<style scoped lang="scss">
div.shoot-day-details {
  display: flex;
  justify-content: center;

  table {
    width: unset;
  }

  th, td {
    width: 175px;
  }
}
</style>

<template>
<tr>
  <td></td>
  <td>
    <select v-model="entryMode">
      <option value="hours">Hours/Rate</option>
      <option value="amount">Amount</option>
    </select>
  </td>
  <td v-if="entryMode == 'hours'">
    <input type="number" v-model="hours" class="hours">
    <span>hrs @</span>
    <select v-model="rate">
      <option v-for="rate in shootAmendmentOptions.rates" :key="rate">
        {{ rate }}
      </option>
    </select>
  </td>
  <td v-else-if="entryMode == 'amount'">
    £ <input type="number" v-model="amount" class="amount">
  </td>
  <td>
    <input type="text" v-model="description" class="description" @keydown.enter="add">
  </td>
  <td>
    <button :disabled="disabled" @click="add">OK</button>
  </td>
</tr>
</template>

<script>
import { shootAmendmentOptions } from '../../data'
import * as api from '../../api'

export default {
  name: 'ShootAmendment',
  props: {
    entry: Object
  },
  data() {
    return {
      editMode: true,
      entryMode: 'hours',
      entryModes: ['hours', 'amount'],
      hours: 0,
      rate: shootAmendmentOptions.rates[0],
      description: '',
      amount: 0
    }
  },
  computed: {
    disabled() {
      return (this.entryMode === 'hours' && this.hours === '') || (this.entryMode === 'amount' && this.amount === '')
    }
  },
  methods: {
    async add() {
      if (this.disabled) {
        return
      }

      const entry_id = this.entry.entry_id
      const action = 'addition'
      let { hours, rate, description, amount } = this
      if (this.entryMode === 'hours') {
        amount = null
      } else if (this.entryMode === 'amount') {
        hours = null
        rate = null
      }
      await api.post('/shoot/amendment', { entry_id, action, hours, rate, description, amount })
      this.$emit('update')
    }
  }
}
</script>

<style scoped lang="scss">
div.amendment {
  display: flex;
  align-items: center;
}

select, input {
  //margin-left: 0.5rem;
  margin-right: 0.25rem;

  &:not(:first-child) {
    margin-left: 0.25rem;
  }
}

select, input, button {
  appearance: auto;
  border: 1px solid #bbb;
  border-radius: 4px;
  padding: 0 2px;
  font-size: 0.85rem;
}

button {
  padding: 0 6px;

  &[disabled=disabled] {
    cursor: not-allowed;
  }
}

input {
  &.hours {
    width: 45px;
    padding-left: 5px;
  }

  &.amount {
    width: 100px;
  }

  &.description {
    width: 100%;
  }
}
</style>

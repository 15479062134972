<template>
<div>
  <div class="d-flex align-center">
    <v-checkbox @change="$emit('select-day', uid, $event)">
      <template v-slot:label>
        <h4 class="black--text">{{ day }}</h4>
      </template>
    </v-checkbox>
    <v-spacer></v-spacer>
    <h3 @click="showDetails = !showDetails" class="job-details">
      <span class="font-weight-regular">{{ job.number }}</span> {{ job.name }}
      <v-icon class="mr-2" :title="target">{{ entryIcons[target] }}</v-icon>

    </h3>
    <v-spacer></v-spacer>
    <v-checkbox label="Invoiced" class="v-input--reverse"
                :input-value="invoiced"
                @change="invoicedChanged(uid, $event)">
    </v-checkbox>
  </div>
  <shoot-day-details ref="details" :job-id="job.id" :date="date"></shoot-day-details>
  <shoot-table :shifts="shifts"></shoot-table>
</div>
</template>

<script>
import * as api from '../../api'
import ShootTable from './ShootTable'
import ShootDayDetails from './ShootDayDetails'

export default {
  name: 'ShootDay',
  components: { ShootDayDetails, ShootTable },
  props: {
    uid: String,
    target: String,
    day: String,
    date: String,
    job: Object,
    shifts: Array,
    invoiced: Boolean
  },
  data() {
    return {
      showDetails: false
    }
  },
  watch: {
    async showDetails() {
      this.$refs.details.show(this.showDetails)
    }
  },
  methods: {
    async invoicedChanged(uids, value) {
      await api.put('/invoiced', { uids, value })
      // TODO: what's this doing here?
      // if (this.outstanding) {
      //   await this.fetchShifts()
      // }
    }
  }
}
</script>

<style scoped>
h3.job-details:hover {
  cursor: help;
}
</style>

<template>
<v-card>
  <v-card-title class="d-block">
    <h4 class="headline d-block">{{ days[0].job.name }}</h4>
    <h4 class="subtitle-1 text--lighten-2">
      {{ days[0].date }} - {{ days[days.length - 1].date }}
    </h4>
  </v-card-title>
  <v-card-text>
    <v-container>
      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
          <template v-for="{ day, entries } in days">
            <tr :key="day">
              <td>{{ day }}</td>
              <td></td>
            </tr>
            <template v-for="shift in entries">
              <tr v-for="(row, r) in JSON.parse(shift.breakdown_json)" :key="day+shift.user_name+r">
                <td>
                  {{ r === 0 ? `${shift.role} (${shift.user_name})` : '' }}
                  {{ row.start }} - {{ row.end }}
                  {{ row.hours }} hours
                  {{ row.description }}
                </td>
                <td>
                  <span>£{{ row.fee }}</span>
                </td>
              </tr>
              <tr :key="day+shift.user_name+'blank'"><td></td><td></td></tr>
            </template>
          </template>
          </tbody>
        </template>
      </v-simple-table>
    </v-container>
  </v-card-text>
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn text color="primary" @click="$emit('mark-invoiced', days)">Mark invoiced</v-btn>
    <v-btn text @click="$emit('close')">Close</v-btn>
  </v-card-actions>
</v-card>
</template>

<script>
export default {
  name: 'ShootExport',
  props: {
    days: Array
  }
}
</script>

<style scoped>

</style>

<template>
<page-wrapper>
  <v-row dense class="top-controls">
    <v-select class="month" v-model="month" :items="availableMonths" label="Month" @change="fetchShifts"></v-select>
    <job-select class="job-select" v-model="jobID" add-all-option @change="fetchShifts"></job-select>
    <v-checkbox v-model="outstanding" label="Outstanding" @change="fetchShifts"></v-checkbox>
    <v-checkbox v-model="flagged" label="Flagged" @change="fetchShifts"></v-checkbox>
    <v-checkbox v-model="awaitingApproval" label="Awaiting approval" @change="fetchShifts"></v-checkbox>
    <v-spacer></v-spacer>
    <v-dialog v-model="showExport" max-width="680px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="export" color="primary" depressed v-bind="attrs"
               :disabled="selectedDays.size === 0"
               @click="exportSelected">Export</v-btn>
      </template>
      <shoot-export :days="exportDays" @close="showExport = false"
                    @mark-invoiced="markInvoiced">
      </shoot-export>
    </v-dialog>
  </v-row>
  <v-progress-linear v-if="!loaded" indeterminate></v-progress-linear>
  <div v-else-if="days.length === 0"
       class="text-center text--lighten-3 ma-4">
    <h3 class="font-italic grey--text">No jobs to display</h3>
  </div>
  <template v-else v-for="{ uid, target, day, date, job, shifts, invoiced } in days">
    <shoot-day :uid="uid" :target="target" :day="day" :date="date" :job="job" :shifts="shifts" :invoiced="invoiced"
               :key="job.number+day"
               @select-day="selectDay">
    </shoot-day>
  </template>
</page-wrapper>
</template>

<script>
import PageWrapper from '../components/ui/PageWrapper'
import * as api from '../api'
import JobSelect from '../components/ui/JobSelect'
import ShootExport from '../components/export/ShootExport'
import ShootDay from '../components/shoots/ShootDay'

export default {
  name: 'Shoots',
  components: { ShootDay, ShootExport, JobSelect, PageWrapper },
  data() {
    return {
      month: null,
      jobID: 0,
      outstanding: false,
      flagged: false,
      awaitingApproval: false,
      availableMonths: [
        { text: 'Recent', value: 'recent' },
        { text: 'All', value: 'all' }
      ],
      days: [],
      selectedDays: new Set(),
      showExport: false,
      exportDays: null,
      loaded: false
    }
  },
  computed: {
    statusOptions() {
      return Array.from(new Set(this.$store.state.jobs.map(j => j.status)))
    }
  },
  methods: {
    fetchShifts() {
      if (this.jobID === null) {
        return
      }
      this.loaded = false

      this.$router.push({
        name: this.$route.name,
        query: {
          month: this.month,
          job_id: this.jobID,
          outstanding: this.outstanding,
          flagged: this.flagged,
          unapproved: this.awaitingApproval
        }
      }).catch(e => {})
    },
    selectDay(uid, selected) {
      const func = selected ? 'add' : 'delete'
      this.selectedDays[func](uid)
      this.$forceUpdate()
    },
    exportSelected() {
      if (this.selectedDays.size > 0) {
        this.exportDays = this.days
          .filter(d => this.selectedDays.has(d.uid))
          .sort((a, b) => a.uid.localeCompare(b.uid))
        this.showExport = true
      }
    },
    markInvoiced(days) {
      const uids = days.map(d => d.uid)
      api.put('/invoiced', { uids, value: true })
      for (const day of this.days) {
        const { uid } = day
        if (uids.includes(uid)) {
          day.invoiced = true
        }
      }
    }
  },
  async beforeRouteUpdate(to, from, next) {
    const { query } = to
    if (!query.month) {
      query.month = 'recent'
      query.job_id = 0
      query.outstanding = false
      query.flagged = false
    }
    this.days = await api.get('/job-times', query)
    this.month = query.month
    this.jobID = parseInt(query.job_id)
    this.outstanding = query.outstanding === true || query.outstanding === 'true'
    this.flagged = query.flagged === true || query.flagged === 'true'
    this.awaitingApproval = query.unapproved === true || query.unapproved === 'true'

    this.loaded = true
    next()
  },
  async created() {
    const months = await api.get('/shoot/months')
    this.availableMonths = this.availableMonths.concat(months)
    if ('month' in this.$route.query) {
      const { month, job_id, outstanding, flagged, unapproved } = this.$route.query
      this.month = month
      this.jobID = parseInt(job_id || 0)
      this.outstanding = outstanding === true || outstanding === 'true'
      this.flagged = flagged === true || flagged === 'true'
      this.awaitingApproval = unapproved === true || unapproved === 'true'
    } else {
      this.month = 'recent'
    }
    const { month, jobID, outstanding, flagged } = this
    const query = { month, job_id: jobID, outstanding, flagged }
    this.days = await api.get('/job-times', query)
    this.loaded = true
  }
}
</script>

<style lang="scss">
.job {
  margin-bottom: 3rem;

  h4.day {
    text-align: center;
  }
}

.top-controls {
  width: 100%;
  align-items: center;

  >* {
    margin: 5px 10px;
  }
  .v-select.month {
    max-width: 110px;
  }

  .job-select {
    max-width: 450px;
  }

  button.export {
    margin-top: -20px;
  }
}
</style>
